var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basics-edit-component el-form-required-position"},[_c('div',{staticClass:"form-item"},[_c('div',{staticClass:"form-label"},[_vm._v("原型详情")]),_c('div',{staticClass:"form-content"},[_c('avue-form',{ref:"form",attrs:{"option":_vm.formOption,"disabled":_vm.isShelves},scopedSlots:_vm._u([{key:"deliveryTime",fn:function(scoped){return [_c('rangeInput',_vm._b({ref:"rangeInput",model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},'rangeInput',Object.assign({}, scoped,
              scoped.column),false),[_c('span',{staticClass:"ml10",attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("天")])])]}},{key:"isTort",fn:function(){return [_c('el-select',{attrs:{"placeholder":"请选择","clearable":""},model:{value:(_vm.form.isTort),callback:function ($$v) {_vm.$set(_vm.form, "isTort", $$v)},expression:"form.isTort"}},_vm._l((_vm.tortList),function(ref){
            var value = ref.value;
            var label = ref.label;
return _c('el-option',{key:value,attrs:{"value":value,"label":label}})}),1)]},proxy:true},{key:"detail",fn:function(ref){
            var prop = ref.prop;
            var placeholder = ref.placeholder;
            var column = ref.column;
return [_c('Editor',{attrs:{"id":"basisEditor","val":_vm.form[prop],"placeholder":placeholder,"height":300,"readonly":column.readonly,"disabled":column.disabled,"serverUrl":"serverUrl","useObjectStr":"CONTENT_UEDITOR_FILE"},on:{"update:val":function($event){return _vm.$set(_vm.form, prop, $event)}}})]}}]),model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }