import store from '@/store'
import { isInteger } from '@/utils'

export const formOption = {
  menuBtn: false,
  span: 10,
  column: [
    {
      label: '原型名称',
      prop: 'name',
      rules: [
        {
          required: true
        }
      ]
    },
    {
      label: '英文名称',
      prop: 'englishName'
    },
    {
      label: '生产工艺',
      prop: 'technology',
      placeholder: '输入工艺名称，如：UV喷印 '
    },
    {
      label: '原型材质',
      prop: 'material',
      placeholder: '输入原型材质，如：棉'
    },
    {
      label: '发货时效',
      prop: 'deliveryTime',
      minProp: 'deliveryTimeMin',
      maxProp: 'deliveryTimeMax',
      formslot: true,
      rules: [
        {
          validator: isInteger
        }
      ]
    },
    {
      label: '是否侵权',
      prop: 'isTort',
      formslot: true,
      rules: [
        {
          required: true
        }
      ]
    },
    {
      label: '刀版图文字层类型',
      prop: 'textLayerType',
      dicType: 'textLayerType',
      type:'radio',
      labelWidth: 150,
      rules: [
        {
          required: true
        }
      ]
    },
    {
      label: '其他描述',
      prop: 'detail',
      span: 24,
      formslot: true
    }
  ]
}

store.dispatch('HandleOption', formOption)